import styled from "styled-components";

export const StyledButton = styled.button<{
  height?: number;
  width?: number;
  blocked?: boolean;
}>`
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  outline: 0;
  border: none;
  border-radius: 12px;
  font-size: 28px;
  font-weight: bold;
  font-feature-settings: "case" on;
  cursor: pointer;
  opacity: ${({ blocked }) => (blocked ? 0.5 : 1)};
  background: #97fc4d;
  color: #000;
  font-family: 'Noto Sans Georgian';

  &:active {
    opacity: ${({ blocked }) => (blocked ? 0.5 : 0.8)};
  }

  &:disabled {
    background: ${(props) => props.theme.colors.transparent3};
    color: ${(props) => props.theme.colors.whiteGrayish};
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 97%;
    height: 100%;
    filter: blur(2px);
    border-radius: 12px;
    z-index: -1;
    background: #5a9356;
  }

  &.green {
    background: #f18f2e;
    color: #fff;

    &::before {
      background: #1f7825;
    }
  }

  &.red {
    background: ${({ theme }) => theme.colors.redPink};
    color: #fff;

    &::before {
      background: #831b2a;
    }
  }

  @media (min-width: ${2800}px) {
    font-size: 21px;
  }

  @media (min-width: ${1920}px) {
    font-size: 28px;
  }
`;

export const ButtonNumericLabel = styled.span``;
