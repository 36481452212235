//export const SOCKET_URL = "wss://dev-boom-demo-api.playtron.com/ws";
//export const SOCKET_URL = 'wss://localhost:50070/ws';
export const SOCKET_URL = 'wss://api-by.devcom.ge/ws';
export const HOME_WEBSITE = "https://europebet.com";
export const CURRENCY_SYMBOL = "$";
export const MIXPANEL_TOKEN = "f8cef70fdabe59faddacf1215ec4efd8";
export const SENTRY_DSN =
  "https://7d99079bf5d143089c4993ba7f4bfebe@o1089540.ingest.sentry.io/6104736";
export const SESSION_KEY = "sessionKey";
export const LANGUAGE_KEY = "lang";
