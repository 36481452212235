import React, { FC } from "react";
import useTranslate from "../../../../../config/language";
import { KEYWORDS } from "../../../../../config/language/keywords";
import { roundNumber } from "../../../../../helpers/functions/round-number";
// import { useGameStatusState } from "../../../../../config/store/state/app.state";
import { useShowMessage } from "../../../../errorMessages/store/services";
import { CURRENCY_SYMBOL } from "../../../../../constants/constants";
import { ButtonInput } from "../../../styles";

const MIN_FREE_BET_CASH_OUT = 1.5;

const CashOutPanel: FC<{
  amount: number;
  multiplier: number;
  handleCashOut(): void;
  disabled?: boolean;
}> = ({ amount, multiplier, handleCashOut, disabled }) => {
  const translate = useTranslate();
  const showMessage = useShowMessage();
  // const game = useGameStatusState();

  const handleBlockedAction = () => {
    showMessage(KEYWORDS.MinFreebetCashout, "error");
  };

  return (
    <ButtonInput
      text={
        <>
          {/* {`${translate(KEYWORDS.Cashout)} ${
            game.multiplier && roundNumber(game.multiplier)
          }x - ${roundNumber(amount)}${CURRENCY_SYMBOL}`} */}
          {`${translate(KEYWORDS.Cashout)} (${roundNumber(
            amount
          )}${CURRENCY_SYMBOL})`}
        </>
      }
      disabled={disabled}
      blocked={multiplier < MIN_FREE_BET_CASH_OUT}
      blockedAction={handleBlockedAction}
      onClick={handleCashOut}
    />
  );
};

export default CashOutPanel;
