import styled from "styled-components";
import NavigationItem from "../../../components/Navigation/NavigationItem";
import Navigation from "../../../components/Navigation/Navigation";

export const StyledNavigation = styled(Navigation)`
  display: flex;
  background: #1b1a2e;
  border-radius: 6px 6px 0 0;
  height: 42px;

  @media (min-width: ${2800}px) {
    height: 45px;
  }

  @media (min-width: ${1920}px) {
    height: 54px;
  }
`;

export const StyledNavigationItem = styled(NavigationItem)<{
  active: boolean;
}>`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  opacity: 0.5;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: transparent;
    bottom: 0;
    left: 0;
  }

  @media (min-width: ${1920}px) {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;

    &::after {
      height: 2.7px;
    }
  }

  ${(props) =>
    props.active &&
    `
    opacity: 1;

    &::after {
      background: #97fc4d;
      // box-shadow: 0px 1.5px 3px rgba(248, 255, 19, 0.68);
    }
`};
`;
