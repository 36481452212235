import React from "react";
import { AuthLayout, AuthSubmit, AuthTitle, RegistrationContainer } from "./styled";
import Button from "../../components/Button/Button";

const Login = () => {
  return (
    <AuthLayout>
      <AuthTitle>Login</AuthTitle>
      <RegistrationContainer>
        <div className="input">
            <label>Nickname</label>
            <input type="text" placeholder="Type your nickname" />
        </div>
        <div className="input">
            <label>Password</label>
            <input type="passowrd" placeholder="Type your password" />
        </div>
      </RegistrationContainer>
      <AuthSubmit>
        <Button text="Log in"></Button>
      </AuthSubmit>
    </AuthLayout>
  );
};

export default Login;
