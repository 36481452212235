import React, { FC, ReactNode } from "react";
import styled from "styled-components";

const StyledButton = styled.button<{
  height?: number;
  width?: number;
  blocked?: boolean;
}>`
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  outline: 0;
  border: none;
  border-radius: 12px;
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
  opacity: ${({ blocked }) => (blocked ? 0.5 : 1)};
  background: #97fc4d;
  color: #fff;
  z-index: 2;

  &:active {
    opacity: ${({ blocked }) => (blocked ? 0.5 : 0.8)};
  }

  &:disabled {
    background: ${(props) => props.theme.colors.transparent3};
    color: ${(props) => props.theme.colors.whiteGrayish};
  }

  &.green {
    background: #f18f2e;
    color: #fff;
  }

  &.red {
    background: ${({ theme }) => theme.colors.redPink};
    color: #fff;
  }

  @media (min-width: ${2800}px) {
    font-size: 21px;
  }

  @media (min-width: ${1920}px) {
    font-size: 28px;
  }
`;

export const ButtonNumericLabel = styled.span`
  // font-family: "Roboto", monospace;
`;

const Background = styled.div`
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
  height: 100%;
  filter: blur(1px);
  border-radius: 10px;
  z-index: 1;
  background: #489A4E;

  @media (max-width: ${1100}px) {
    bottom: -6px;
  }

  &.green {
    background: #9e5f22;
  }

  &.red {
    background: #831b2a;
  }
`;

const Button: FC<{
  text?: string | ReactNode;
  height?: number;
  width?: number;
  onClick?(): void;
  blockedAction?(): void;
  className?: string;
  disabled?: boolean;
  blocked?: boolean;
}> = ({
  text,
  height,
  width,
  onClick,
  blockedAction,
  className,
  disabled,
  blocked,
}) => {
    return (
      <div style={{ position: "relative", width: "100%", maxHeight: '90%' }}>
        <StyledButton
          className={className}
          height={height}
          width={width}
          onClick={blocked ? blockedAction : onClick}
          disabled={disabled}
          blocked={blocked}
        >
          {text}
        </StyledButton>

        <Background className={className} />
      </div>
    );
  };

export default Button;
