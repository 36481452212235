import React, { FC, memo, useRef, useState } from "react";
import styled from "styled-components";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const StyledNumberInput = styled.div<{ fontSize: number; disabled?: boolean }>`
  height: 100%;
  width: 100%;
  min-height: 30px;
  background: #1b1a2e;
  position: relative;
  border-radius: 18px !important;

  input {
    padding: 0 !important;
  }

  & input {
    display: block;
    border: none;
    text-align: center;
    padding: 0 25px 0 16px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.white};
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    outline: none;
    font-size: ${(props) => props.fontSize}px;
    s &:disabled {
      opacity: 0.5;
      font-size: ${(props) => props.fontSize}px;
      color: ${(props) => props.theme.colors.whiteGrayish} !important;
      text-shadow: none;
    }
  }

  @media (max-height: ${550}px), (max-width: ${600}px) {
    & input {
      font-size: 16px;
      &:disabled {
        font-size: 16px;
      }
    }
  }
  @media (max-width: ${1100}px) {
    & input {
      font-size: 20px;
      padding: 0 20px 0 4px;
      &:disabled {
        font-size: 16px;
      }
    }
  }
`;

const Input = styled(MaskedInput)`
  height: 100%;
  width: 100%;
  font-family: "Solarx";
  color: #ffbf3d;
  line-height: 40px;
`;

const Arrows = styled.div<{ disabled?: boolean }>`
  position: absolute;
  width: 30px;
  height: 100%;
  bottom: 0;
  left: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;


  ${(props) =>
    props.disabled &&
    `
        opacity: 0.5;
        pointer-events: none;
    `}
`;

const ArrowContainer = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  color: #fff;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 2px 0;
  &:active {
    opacity: 0.9;
  }
`;

const ArrowIcon = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  color: #456197;
`;

const defaultMaskOptions = {
  prefix: "",
  suffix: ` A`,
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 2,
  integerLimit: 10,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const emptyMaskOptions = {
  prefix: "",
  suffix: "",
};

function formatDecimalsNumber(value: number | null) {
  if (!value) return 0;
  return Number(Math.round(parseFloat(value + "e" + 2)) + "e-" + 2);
}

const NumberInput: FC<{
  suffix?: string;
  prefix?: string;
  value: number;
  onChange: (value: number) => void;
  step?: number;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  fontSize?: number;
  className?: string;
  arrowClass?: string;
  hideArrow?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}> = ({
  value,
  onChange,
  suffix,
  prefix,
  step = 0.1,
  disabled,
  minValue = 0.1,
  maxValue,
  fontSize = 24,
  className,
  hideArrow,
  arrowClass,
}) => {
  const inputRef = useRef<any>(null);

  const maskOptions = {
    suffix: suffix ? suffix : defaultMaskOptions.suffix,
    prefix: prefix ? prefix : defaultMaskOptions.prefix,
  };

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  const focusedMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
    ...emptyMaskOptions,
  });

  const handleInputChange = (val: string) => {
    let suffix = maskOptions?.suffix || defaultMaskOptions.suffix;
    let prefix = maskOptions?.prefix || defaultMaskOptions.prefix;
    let updatedValue = val.replace(suffix, "");
    updatedValue = updatedValue.replace(prefix, "");
    onChange(Number(updatedValue));
  };
  const handleIncrement = () => {
    if (value < 1) {
      step = 0.1;
    }
    onChange(formatDecimalsNumber(Number(value + step)));
  };

  const handleDecrement = () => {
    if (value <= 1) {
      step = 0.1;
    }

    onChange(formatDecimalsNumber(Number(value - step)));
  };

  const [hideMask, setHideMask] = useState(false);

  return (
    <StyledNumberInput
      disabled={disabled}
      fontSize={fontSize}
      className={className}
    >
      <Input
        ref={inputRef}
        onBlur={() => {
          setHideMask(false);
        }}
        onFocus={(event) => {
          setHideMask(true);
          const val = event.target.value;
          event.target.value = "";
          event.target.value = val;
        }}
        mask={hideMask ? focusedMask : currencyMask}
        value={value}
        step={step}
        min={minValue}
        max={maxValue}
        onChange={(event) => {
          handleInputChange(event.target.value);
        }}
        disabled={disabled}
        inputMode="decimal"
      />

      {!hideArrow && (
        <Arrows disabled={disabled} className={arrowClass}>
          <ArrowContainer onClick={!disabled ? handleDecrement : undefined}>
            <ArrowIcon>
              -
            </ArrowIcon>
          </ArrowContainer>
          <ArrowContainer onClick={!disabled ? handleIncrement : undefined}>
            <ArrowIcon>
              +
            </ArrowIcon>
          </ArrowContainer>
        </Arrows>
      )}
    </StyledNumberInput>
  );
};

export default memo(NumberInput);
