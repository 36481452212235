import React, { FC } from "react";
import Modal from "../Modal";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  outline: 0;
  padding: 40px 18px;
  border-radius: 6px;
  background: #320703;
  box-shadow: none;
  width: 314px;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: ${2800}px) {
    width: 715px;
    padding: 54px 20px;
    border-radius: 8px;
  }
`;

const Text = styled.div`
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-transform: uppercase;

  font-size: 16px;
  line-height: 22px;

  @media (min-width: ${2800}px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

const Actions = styled.div`
  margin-top: 69px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${2800}px) {
    margin-top: 107px;
  }
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.frogGreen};
  font-feature-settings: "case" on;
  border-radius: 6px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  padding: 12px;
  cursor: pointer;

  @media (min-width: ${2800}px) {
    border-radius: 8px;
    font-size: 22px;
    line-height: 27px;
    padding: 16px 15px;
  }
`;

export const ErrorModalTemplate: FC<{
  children: React.ReactChildren;
  action?: () => void;
  actionText?: string;
}> = ({ children, action, actionText }) => {
  return (
    <Modal>
      <Container>
        <Text>{children}</Text>
        {!!action && (
          <Actions>
            {/* <Button onClick={action}>{actionText}</Button> */}
            <Button onClick={action}>გახსენი აქ</Button>
          </Actions>
        )}
      </Container>
    </Modal>
  );
};
